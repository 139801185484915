import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FaMapMarkerAlt } from 'react-icons/fa';

import logoFooter from '../../assets/logo_footer.png';
import logoena from '../../assets/logo_enashopp.png';

import api from '../../services/api';

import instagram from '../../assets/footer_instagram.png';
import facebook from '../../assets/footer_facebook.png';
import linkedin from '../../assets/footer_linkedin.png';
import youtube from '../../assets/footer_youtube.png';

import styles from './styles.module.scss';

export function Footer(){

  
  const [enderecos, setEnderecos] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [menuFooter, setMenuFooter] = useState([]);
  const [telefones, setTelefones] = useState([]);

  useEffect(() => {

    async function loadMenuFooter() {

      const response = await api.get(process.env.REACT_APP_INSTITUCIONAL_MENU);

      setMenuFooter(response.data);
    }

    loadMenuFooter();

  }, []);

  useEffect(() => {

    async function loadHorarios() {

      const response = await api.get(process.env.REACT_APP_INSTITUCIONAL_HORARIOS);

      setHorarios(response.data);
    }

    loadHorarios();

  }, []);

  useEffect(() => {

    async function loadTelefone() {

      const response = await api.get(process.env.REACT_APP_CONTATO);

      setTelefones([response.data[0]]);
    }

    loadTelefone();

  }, []);

  useEffect(() => {

    async function loadEnderecos() {

      const response = await api.get(process.env.REACT_APP_CONTATO);

      setEnderecos([response.data[1]]);
    }

    loadEnderecos();

  }, []);

  return (
    <>
      <footer>
          <hr/>
          <div className="container">
            <div className={styles.footerContent}>
              <div>
                <span className={styles.title}>Institucional</span>
                <ul>
                  {menuFooter.map(menu => (
                    <li key={menu.id}>
                      <Link to={`/${menu.slug}`} title={menu.page}>{menu.page}</Link>
                    </li>
                  ))}
                  <li><a href="http://enashopp.com.br/new/" target="_blank" rel="noreferrer">Enashopp</a></li>
                  <li><a href="http://vagas.intranetmall.com.br/shoppingparquedacidade/" target="_blank" rel="noreferrer">Trabalhe Conosco</a></li>  
                  <li><Link to="/contato">Contato</Link></li>
                </ul>
                <br /><br className={styles.hideMobile}/>
                <span className={styles.title}>Telefone</span>
                {telefones.map(telefone => (
                  <p key={telefone.id}>
                  {telefone.text.replace(/<[^>]*>/g, '')}</p>
                ))}
              </div>
              <div>
                <span className={styles.title}>Horário de funcionamento</span>
                {horarios.map(horario => (
                  <Fragment key={horario.id}>
                    <p className={styles.horarioTitle}><strong>{horario.title}</strong></p>
                    <div dangerouslySetInnerHTML={{ __html: horario.text}} className={styles.horarios}></div>
                  </Fragment>
                ))}
              </div>
              <div>
                <span className={styles.title}>Endereço</span>
                  {enderecos.map(endereco => (
                    <div key={endereco.id} className={styles.enderecos} dangerouslySetInnerHTML={{ __html: endereco.text}}></div>
                  ))}
                <FaMapMarkerAlt size={20} color="#000000" className={styles.footerIcone} /> &nbsp;
                <a href="http://maps.google.com/maps?q=Av. das Nações Unidas, 14401 - Chácara Santo Antônio São Paulo - SP 04794-000" target="_blank" rel="noreferrer">
                  <span>Como chegar</span>
                </a>    
              </div>
              <div className={styles.sociais}>
                <div className={styles.logoFooter}>
                  <img src={logoFooter} alt="Shopping Parque da Cidade" width="100%" />
                </div>
                <ul className={styles.Redessociais}>
                  <li>
                    <a href="https://www.instagram.com/_shoppingparquedacidade/" target="_blank" rel="noreferrer" title="Instagram">
                      <img src={instagram} alt="Instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/shoppingparquedacidade" target="_blank" rel="noreferrer" title="Facebook">
                      <img src={facebook} alt="Facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/shopping-parque-da-cidade" target="_blank" rel="noreferrer" title="Linkedin">
                      <img src={linkedin} alt="Linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UCB_rkn8eDylbZIn5c7A3OQw" target="_blank" rel="noreferrer" title="Youtube">
                      <img src={youtube} alt="Youtube" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr/>
          <div className="container">
            <div className={styles.footerContent2}>
              <div className={styles.logoEna}>
                <a href="http://enashopp.com.br/new/" target="_blank" rel="noreferrer"><img src={logoena} alt="Enashopp" /></a>
              </div>
              <div>
                <p>© Shopping Parque da Cidade - Todos os direitos reservados</p>
              </div>
            </div>
          </div>
      </footer>
    </>
  );
}
