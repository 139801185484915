import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { decode } from 'html-entities';

import Helmet from 'react-helmet';

import { FaUserPlus } from 'react-icons/fa';

import api from '../../services/api';
import spotApi from '../../services/spotApi';

import { ModalPfForm } from '../../components/ModalPfForm';
import { ModalPjForm } from '../../components/ModalPjForm';

import iconBusiness from '../../assets/add_business.svg';

import styles from './ClubeDoParque.module.scss';

export function ClubedoParque() {

  const [modalPF, setModalPF] = useState(false);
  const [modalPJ, setModalPJ] = useState(false);
  const [banner, setBanner] = useState([]);
  const [clubes, setClubes] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const mallId = process.env.REACT_APP_MALLID;


  const handleCadastroPf = () => {
    setModalPF(true);
  }

  const handleCadastroPj = () => {
    setModalPJ(true);
  }

  useEffect(() => {

    async function loadBanner() {

      const response = await api.get(process.env.REACT_APP_BANNER_CLUBEDOPARQUE);

      setBanner(response.data);
    }

    loadBanner();
  }, []);

  useEffect(() => {

    async function loadBeneficios() {

      const response = await api.get(process.env.REACT_APP_CLUBEDOPARQUE_BENEFICIOS_SOBRE);

      setClubes(response.data);
    }

    loadBeneficios();
  }, []);

  useEffect(() => {

    async function loadEmpresas() {

      const response = await spotApi.get(`${process.env.REACT_APP_MOSURL}/${process.env.REACT_APP_STORE_URL}${mallId}`);

      setEmpresas(response.data);
    }

    loadEmpresas();

  }, [mallId]);



  return (
    <>
      <Helmet>
        {
          banner.map(banner => (
            <meta name="description" content={banner.title} key={banner.id} />
          ))
        }
        <title>Shopping Parque da Cidade » Clube do Parque » Sobre</title>
      </Helmet>

      <main>

        {modalPF && (
          <>
            <ModalPfForm open={modalPF} close={() => setModalPF(false)} empresas={empresas} />
          </>
        )}

        {modalPJ && (
          <>
            <ModalPjForm open={modalPJ} close={() => setModalPJ(false)} />
          </>
        )}

        {banner.map(banner => (
          <React.Fragment key={banner.id}>
            <div className={styles.clubeDoParqueBanner}>
              <div className={styles.clubeDoParqueFilter}>
                <div className={styles.banner}>
                  <img src={`${process.env.REACT_APP_IMAGES_URL}bannersClubeDoParque/${banner.file}`} alt={banner.title} />
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
        <section id={styles.beneficios}>
          <div className="container">
            <p>O Clube do Parque é o programa gratuito de benefício do Shopping Parque da Cidade. Proporciona benefícios especiais como conveniências na praça de alimentação, descontos em lojas, convites especiais para eventos e promoções, além de oferecer 50% de desconto* no Kinoplex.</p>
            <p>Benefício: 50% de desconto em todas as salas de cinema. Válido de segunda a sexta-feira, exceto feriados. Desconto aplicado apenas sobre o valor do ingresso “inteira” do dia, nas compras presenciais no Kinoplex Parque da Cidade.</p>
            <div className={styles.beneficiosContentGrid}>
              <div className={styles.bg}>
                <FaUserPlus size={40} color="#485860" />
                <p>Clique aqui e cadastre gratuitamente<br /> ou vá direto ao Concierge do Shopping (piso 1, ao lado da CVC).</p>
                <button onClick={handleCadastroPf} type="button">Cadastre-se</button>
              </div>
              <div className={styles.bg}>
                <img src={iconBusiness} alt="Add Business" width="40" />
                <p>Clique aqui, cadastre sua empresa e faça parcerias especiais para seus colaboradores.</p>
                <button onClick={handleCadastroPj} type="button">Cadastre sua empresa</button>
              </div>
            </div>
          </div>
        </section>
        <section id={styles.confira}>
          <div className="container">
            <h1>Confira alguns dos benefícios</h1>
            <div className={styles.confiraContent}>
              {
                clubes.map((clube, index) => (
                  <React.Fragment key={index}>
                    <div id={clube.id}>
                      <div className={styles.logoClube}>
                        <img src={`${process.env.REACT_APP_IMAGES_URL}clubedoparque/${clube.file}`} alt={clube.name} width="100%" />
                      </div>
                      <p>{clube.name}</p>
                      <span>{decode(clube.bio.replace(/<[^>]*>/g, ''))}</span>
                    </div>
                  </React.Fragment>
                ))
              }
            </div>
            <Link to="/clubedoparque/beneficios" className={styles.beneficiosConfira}>
              Confira os demais benefícios
            </Link>
          </div>

        </section>
      </main>
    </>
  );
}
