import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { FaUser, FaBuilding, FaCheck } from 'react-icons/fa';
import { GrMail, GrClose, GrInternetExplorer } from 'react-icons/gr';
import { IoFingerPrintSharp } from 'react-icons/io5';
import { MdSmartphone, MdLocationOn } from 'react-icons/md';

import spotApi from '../../services/spotApi';
import api from '../../services/api';

import cep from '../../services/cep';

import styles from '../ModalLRB/styles.module.scss';

export function ModalPjForm(props) {


    const [lucs, setLucs] = useState(16415);
    const [nome, setNome] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [CEP, setCEP] = useState('');
    const [endereco, setEndereco] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [uf, setUF] = useState('');
    const [numColab, setNumColab] = useState('');
    const [segmento, setSegmento] = useState('');
    const [site, setSite] = useState('');
    const [nomeRH, setNomeRH] = useState('');
    const [cpf, setCpf] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [genero, setGenero] = useState('');
    const [news, setNews] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);

    const [displayError, setDisplayError] = useState(false);

    const empID = () => {
        setLucs(lucs + 10);
    };

    const pesquisaCEP = () => {
        cep.get(`/${CEP}/json`).then(response => {
            const foundCEP = response.data;
            setEndereco(foundCEP.logradouro);
            setBairro(foundCEP.bairro);
            setCidade(foundCEP.localidade);
            setUF(foundCEP.uf);
        })
            .catch(error => console.log(error))
    }

    async function SearchStoreData() {

        const mallId = process.env.REACT_APP_MALLID;
        const FormatedCNPJ = cnpj.replace('.', '').replace('-', '').replace('.', '').replace('/', '');

        const foundCNPJ = await spotApi.get(`${process.env.REACT_APP_MOSURL}/${process.env.REACT_APP_GET_STORE_DATA_URL}${mallId}&cnpj=${FormatedCNPJ}`);

        await foundCNPJ.data.total === 0 ? setDisplayError(false) : setDisplayError(true);

    }

    async function SendFormCadPJ(e) {

        try {

            const FormatedCNPJ = cnpj.replace('.', '').replace('-', '').replace('.', '').replace('/', '');
            const FormatedCPF = cpf.replace('.', '').replace('-', '').replace('.', '');
            const FormatedCEP = CEP.replace('-', '');
            const FormatedTelefone = telefone.replace('-', '').replace('(', '').replace(')', '').replace(' ', '');
            const optInNews = news === true ? 'Sim' : 'Não';

            const data = {
                "mallId": 34,
                "originRegistryId": 27,
                "active": true,
                "mccActivityId": 10,
                "employeeRegisterId": 5,
                "activities": [10],
                "name": nome,
                "legalName": nome,
                "cnpj": FormatedCNPJ,
                "url": site,
                "type": "loja",
                "lucs": [lucs],
                "customFields": {
                    "zipCode": FormatedCEP,
                    "address": endereco,
                    "number": numero,
                    "complement": complemento,
                    "neighborhood": bairro,
                    "city": cidade,
                    "state": uf,
                    "employees": numColab,
                    "rhCPF": FormatedCPF,
                    "rhGenero": genero,
                    "rhOptInNewsletter": optInNews
                },
                "contactName": nomeRH,
                "email": email,
                "contactPhone": FormatedTelefone,
                "phones": [FormatedTelefone],
                "presentation": {
                    "title": nome,
                    "description": segmento
                }
            }

            await spotApi.post(`${process.env.REACT_APP_MOSURL}/${process.env.REACT_APP_POST_STORE_URL}`, data);

            api.post(`${process.env.REACT_APP_API_PRODUCTION_URL}/${process.env.REACT_APP_CLUBEDOPARQUE_PJMAIL}`, { nome, email });

            setSuccessMsg(true);

            setNome('');
            setCnpj('');
            setEndereco('');
            setNumero('');
            setComplemento('');
            setBairro('');
            setCidade('');
            setUF('');
            setNumColab('');
            setSegmento('');
            setSite('');
            setNomeRH('');
            setCpf('');
            setEmail('');
            setTelefone('');
            setGenero('');


            setTimeout(function () { setSuccessMsg(false); }, 3000);

        } catch (error) {
            alert(error.response.data.message);
            //alert('Não foi possível realizar o seu cadastro no momento, tente novamente mais tarde.');
        }
    }

    return (
        <>
            <div className={props.open ? `${styles.modalBG} ${styles.showModal} ` : `${styles.modalBG}`}>
                <div className={styles.modalContato}>
                    <GrClose size={30} color="#ffffff" className={`${styles.modalIcon} ${styles.close}`} onClick={props.close} />
                    <div className={styles.identificacao}>
                        <h2>Cadastre sua Empresa</h2>
                    </div>
                    <div className={styles.headerForm}>
                        <h2>Informações da Empresa</h2>
                        <span><i>*preenchimento obrigatórios</i></span>
                    </div>
                    <div className={styles.modalContentContato}>
                        <form>
                            <div className={styles.mbInput}>
                                <input type="text" name="nome-fantasia" placeholder="*Nome Fantasia" onChange={(e) => setNome(e.target.value)} value={nome} />
                                <label><FaBuilding color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={displayError ? `${styles.mbInput} errorCnpj` : `${styles.mbInput}`}>
                                <InputMask mask="99.999.999/9999-99" type="tel" name="cnpj" placeholder="*CNPJ"
                                    onChange={(e) => setCnpj(e.target.value)} value={cnpj}
                                    onBlur={SearchStoreData} />
                                <label><IoFingerPrintSharp color="#dbdbdb" size={18} /></label>
                                {displayError && <div className="errorCnpj"><i>*CNPJ já cadastrado</i></div>}
                            </div>
                            <div className={styles.mbInput}>
                                <InputMask mask="99999-999" type="tel" name="cep" placeholder="*CEP" onChange={(e) => setCEP(e.target.value)} onBlur={pesquisaCEP} value={CEP} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="endereco" placeholder="*Endereço" onChange={(e) => setEndereco(e.target.value)} value={endereco} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="tel" name="numero" placeholder="*Número" onChange={(e) => setNumero(e.target.value)} value={numero} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="complemento" placeholder="Complemento" onChange={(e) => setComplemento(e.target.value)} value={complemento} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="bairro" placeholder="*Bairro" onChange={(e) => setBairro(e.target.value)} value={bairro} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="cidade" placeholder="*Cidade" onChange={(e) => setCidade(e.target.value)} value={cidade} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="uf" placeholder="*UF" onChange={(e) => setUF(e.target.value)} value={uf} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <select name="numero-colaboradores" onChange={(e) => setNumColab(e.target.value)} value={numColab}>
                                <option value="numero-colaboradores">Número de Colaboradores</option>
                                <option value="1 a 10 funcionários">1 a 10 funcionários</option>
                                <option value="11 a 50 funcionários">11 a 50 funcionários</option>
                                <option value="51 a 100 funcionários">51 a 100 funcionários</option>
                                <option value="101 a 300 funcionários">101 a 300 funcionários</option>
                                <option value="301 a 500 funcionários">301 a 500 funcionários</option>
                                <option value="501 a 1000 funcionários">501 a 1000 funcionários</option>
                                <option value="Mais de 1000 funcionários">Mais de 1000 funcionários</option>
                            </select>
                            <div className={styles.mbInput}>
                                <input type="text" name="segmento" placeholder="*Segmento" onChange={(e) => setSegmento(e.target.value)} value={segmento} />
                                <label><FaBuilding color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="site" placeholder="*Site da Empresa" onChange={(e) => setSite(e.target.value)} value={site} />
                                <label><GrInternetExplorer color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.headerForm}>
                                <h2>Contato do RH</h2>
                                <span><i>*preenchimento obrigatórios</i></span>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="nome" placeholder="*Nome" onChange={(e) => setNomeRH(e.target.value)} value={nomeRH} />
                                <label><FaUser color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <InputMask mask="999.999.999-99" type="tel" name="cpf" placeholder="*CPF" onChange={(e) => setCpf(e.target.value)} value={cpf} />
                                <label><IoFingerPrintSharp color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="email" name="email" placeholder="*E-mail Profissional" onChange={(e) => setEmail(e.target.value)} value={email} />
                                <label><GrMail color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <InputMask type="tel" mask={telefone.length > 14 ? '(99) 99999-9999' : '(99) 9999-99999'} maskChar={null} name="telefone" placeholder="*Telefone Empresa / Celular" onChange={e => setTelefone(e.target.value)} value={telefone} />
                                <label><MdSmartphone color="#dbdbdb" size={18} /></label>
                                <div className={styles.infosInput}><p>(DDD + número)</p></div>
                            </div>
                            <div className={`${styles.mbInput} ${styles.inputRadios}`}>
                                <div><h3>Gênero</h3></div><br />
                                <div><input type="radio" name="genero" id="feminino" defaultChecked={genero === 'F'} value="F" onClick={(e) => setGenero('F')} /> Feminino</div>
                                <div><input type="radio" name="genero" id="masculino" defaultChecked={genero === 'M'} value="M" onClick={(e) => setGenero('M')} /> Masculino</div>
                                <div><input type="radio" name="genero" id="nao_informar" defaultChecked={genero === 'O'} value="O" onClick={(e) => setGenero('O')} /> Prefiro não informar</div>
                            </div>
                            <div className={`${styles.mbInput} ${styles.inputCheckbox}`}>
                                <div><input type="checkbox" name="info_mail" value={news} onChange={e => setNews(e.target.checked)} checked={news === true} /> <p>Deseja receber novidades, ofertas e promoções por e-mail?</p></div>
                            </div>
                        </form>
                        {!displayError && <button className={styles.formButtonIdentifica} onClick={SendFormCadPJ} onMouseEnter={empID}>Cadastrar</button>}
                        {successMsg && <div className="sucesso"><FaCheck color="#63a36a" size={25} />&nbsp; Cadastro realizado com sucesso!</div>}
                    </div>
                </div>
            </div>
        </>
    );
}

