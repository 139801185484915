import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { ModalGallery } from '../../components/ModalGallery';

import Helmet from 'react-helmet';

import api from '../../services/api';

import styles from './aconteceNoParque.module.scss';

export function AconteceNoParque(){

  const { name } = useParams();
  let history = useHistory();
  const folder = "acontecenoparque";

  const [AconteceNoParque, setAconteceNoParque] = useState([]);
  const [title, setTitle] = useState('');
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState(false);



  function handleModal(e, image) {
    setModalContent(image);
    setModal(true);
  }


  useEffect(() => { 
    
    async function loadContent() {

      const response = await api.get(`${process.env.REACT_APP_ACONTECENOPARQUE_CONTENT}/${name}`);

      const data = response.data;
      
      if(!data){
        history.push("/");
      }else {
        setAconteceNoParque([response.data]);
        setTitle(response.data.page);
      }
      
    }

    loadContent();

  }, [name, history]);

  return (
    <>
      <Helmet>
        {
          AconteceNoParque.map(content => (
            <meta name="description" content={content.BannerID.title} key={content.id} />
          ))
        }
        <title>Shopping Parque da Cidade » Acontece no Parque » {title}</title>
      </Helmet>
      <main>
        {modalContent && (<ModalGallery open={modal} close={() => setModal(false)} image={modalContent} folder={folder} />)}
        {AconteceNoParque.map(content => (
          <div key={content.id}>
            <div className={styles.aconteceNoParqueBanner}>
              <div className={styles.aconteceNoParqueFilter}>
                <div className={styles.banner}>
                  <img src={`${process.env.REACT_APP_IMAGES_URL}bannersAconteceNoParque/${content.BannerID.file}`} alt={content.page} className={styles.BannerDesk}  />
                  <img src={`${process.env.REACT_APP_IMAGES_URL}bannersAconteceNoParque/${content.BannerID.file_mobile}`} alt={content.page} className={styles.BannerMobile} />
                </div>
              </div>
            </div>
            <section id={styles.informativo}>
              <div className="container">
                <div dangerouslySetInnerHTML={{ __html: content.text}}></div>
                  {content.gallery && (
                    <>
                       <h1 className={styles.galleryTitle}>{content.galleryTitle}</h1>
                       <div className={styles.gallery}>
                        {content.gallery.split(',').map((image, index) => (
                          <div key={index}>
                            <img src={`${process.env.REACT_APP_IMAGES_URL}acontecenoparque/${image}`} alt={`Imagem ${index}`} onClick={(e) => handleModal(e, image)} />
                          </div>
                        ))}
                      </div>
                    </>
                    
                  )}
                  {content.videos && (
                    <>
                      <h1 className={styles.galleryTitle}>{content.videosTitle}</h1>
                      <div className={styles.videos}>
                        {content.videos.split(',').map((video, index) => (
                          <div key={index}>
                            {/* <img src={`${process.env.REACT_APP_IMAGES_URL}acontecenoparque/${image}`} alt={`Imagem ${index}`} /> */}
                            <iframe src={`https://www.youtube.com/embed/${video}`} title={`Vídeo ${index}`}></iframe>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
              </div>
            </section>
          </div>
        ))}
      </main>
    </>
  );
}

export default AconteceNoParque;