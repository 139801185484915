import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import api from '../../services/api';

import 'swiper/swiper-bundle.css';
import styles from './styles.module.scss';

export function SlideHome() {
  const [slides, setSlides] = useState([]);
  SwiperCore.use([Pagination, Autoplay]);

  useEffect(() => {

    async function loadSlides() {

      const response = await api.get(process.env.REACT_APP_SLIDES_URL);

      setSlides(response.data);
    }

    loadSlides();
  }, []);

  return (
    <Swiper slidesPerView={1} pagination={{ clickable: true }} autoplay>
        {slides.map(slide => (
          <SwiperSlide className={styles.swiperSlide} key={slide.id}>
            <div className={styles.slideFilter}>
              <Link to={`/${slide.link}`}>
                <img src={`${process.env.REACT_APP_IMAGES_URL}slides/${slide.file}`} alt={slide.title} className={styles.slideDesk} />
                {/* <img src={`https://shoppingparquedacidade.s3-sa-east-1.amazonaws.com/${slide.file_mobile}`} alt={slide.title} className={styles.slideMobile} /> */}
              </Link>
            </div>
          </SwiperSlide>
        ))}
    </Swiper>
  );
}
