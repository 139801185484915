import axios from 'axios';

const spotApi = axios.create({
  headers: {
    "x-partner-key": "6fc1TiMz@rTe*x$kQsNSInNf*A6Vw4VuPH&X&O%qhqsSA5A3Co",
    //"x-partner-key": "e46c65fc-7ac0-43b4-b364-6f021831eda4",
    "Content-Type": "application/json",
  },
  baseURL: process.env.REACT_APP_MOSURL,
});

export default spotApi;

//REACT_APP_MOSURL=https://mos-api-prd.spotmetrics.com/api/app/v1
//REACT_APP_API_PRODUCTION_URL=https://api-spdc.ideiasadman.com.br