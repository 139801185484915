import React, {useEffect, useState} from 'react';
import { parseString } from 'xml2js';
import slugify from 'react-slugify';
import api from '../../services/api';

import styles from '../../pages/Cinema/cinema.module.scss';

export function Filmes(){
  const [programacaoFilmes, setProgramacaoFilmes] = useState([]);


  useEffect(() => {

    api.get(process.env.REACT_APP_KINOPLEX).then(function(response) {

      const filmesXml = response.data;
      

      parseString(filmesXml, function (err, result) {
        const filmesJSON = result.kinoplex.programacao;

        const filmesArray = filmesJSON.map(cinema => {
          return cinema.filmes[0].filme;
        });
        
        
        setProgramacaoFilmes(filmesArray[0]);

      });

    }).catch(function (error) {
      console.log(error);
    });
  }, []);


  return (
    <>
      <section id={styles.programacao}>
        <div className="container">
          <div className={styles.filmesContent}>
            {
              programacaoFilmes.map(item =>(
                <React.Fragment key={item['$']['id']}>
                  <div>
                    <a href="http://bit.ly/spdc_kinoplex" target="_blank" rel="noreferrer" title={item['titulo-portugues']}>
                      <div id={item['$']['id']}  className="capa-filme">
                          <img src={`https://www.kinoplex.com.br/filmes/images/cartaz/262x388/${slugify(item['titulo-portugues']).replace('---','-')}.jpg`} alt={item['titulo-portugues']} width='100%' />
                      </div>
                      <div className={styles.titulo}>
                        {item['titulo-portugues']}
                      </div>
                      {item['generos'].map(genero => (
                        <div key={genero} className={styles.genero}>{genero['genero'][0]['$']['amigavel']} | {item['duracao']} min.<div className={styles.classificacao}>{item['classificacao']}</div></div>

                      ))} 
                      <span className={styles.linkButton} href="http://bit.ly/spdc_kinoplex" target="_blank">Ver Sessões</span>
                    </a>
                  </div>
                </React.Fragment>
              ))
            }
          </div>
          {/* <div className="erro">Programação indisponível no momento</div> */}
        </div>
      </section>
    </>
  );
}
