import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { AiFillCalendar } from 'react-icons/ai';
import { FaUser, FaBuilding, FaCheck } from 'react-icons/fa';
import { GrMail, GrClose } from 'react-icons/gr';
import { IoFingerPrintSharp } from 'react-icons/io5';
import { MdSmartphone, MdLocationOn } from 'react-icons/md';

import styles from '../ModalLRB/styles.module.scss';

import spotApi from '../../services/spotApi';
import api from '../../services/api';

import cep from '../../services/cep';

export function ModalPfForm(props) {

    const [cpf, setCpf] = useState('');
    const [nome, setNome] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [dataNasc, setDataNasc] = useState('');
    const [genero, setGenero] = useState('');
    const [CEP, setCEP] = useState('');
    const [endereco, setEndereco] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [uf, setUF] = useState('');
    const [news, setNews] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);

    const [options, setOptions] = useState([]);
    const [display, setDisplay] = useState(false);
    const [displayError, setDisplayError] = useState(false);

    const pesquisaCEP = () => {
        cep.get(`/${CEP}/json`).then(response => {
            const foundCEP = response.data;
            setEndereco(foundCEP.logradouro);
            setBairro(foundCEP.bairro);
            setCidade(foundCEP.localidade);
            setUF(foundCEP.uf);
        })
            .catch(error => console.log(error))
    }

    async function SearchClientData() {

        const mallId = process.env.REACT_APP_MALLID;
        const FormatedCPF = cpf.replace('.', '').replace('-', '').replace('.', '');

        const foundCPF = await spotApi.get(`${process.env.REACT_APP_MOSURL}/${process.env.REACT_APP_GET_CLIENT_DATA_URL}${mallId}&cpf=${FormatedCPF}`);

        await foundCPF.data.total === 0 ? setDisplayError(false) : setDisplayError(true);

    }

    async function SendFormCadPF(e) {
        e.preventDefault();

        try {

            const FormatedCPF = cpf.replace('.', '').replace('-', '').replace('.', '');
            const FormatedCEP = CEP.replace('-', '');
            const ano = dataNasc.slice(6, 10);
            const mes = dataNasc.slice(3, 5);
            const dia = dataNasc.slice(0, 2);
            const FormatedTelefone = telefone.replace('-', '').replace('(', '').replace(')', '').replace(' ', '');
            const optInNews = news === true ? true : false;

            const data = {
                "originRegistryId": 27,
                "cpf": FormatedCPF,
                "fullName": nome,
                "birthday": `${ano}-${mes}-${dia}`,
                "sex": genero,
                "mobileNumber": FormatedTelefone,
                "email": email,
                "mallId": 34,
                "address": {
                    "zipCode": FormatedCEP,
                    "street": endereco,
                    "number": numero,
                    "complement": complemento,
                    "neighborhood": bairro,
                    "city": cidade,
                    "state": uf
                },
                "customFields": {
                    "empresa": empresa,
                },
                "signatures": {
                    "communicationEmail": {
                        "valid": optInNews
                    }
                }
            }


            await spotApi.post(`${process.env.REACT_APP_MOSURL}/${process.env.REACT_APP_CLIENT_URL}`, data);


            api.post(`${process.env.REACT_APP_API_PRODUCTION_URL}/${process.env.REACT_APP_CLUBEDOPARQUE_PFMAIL}`, { nome, email });

            setSuccessMsg(true);

            setCpf('');
            setNome('');
            setEmpresa('');
            setTelefone('');
            setEmail('');
            setDataNasc('');
            setGenero('');
            setCEP('');
            setEndereco('');
            setNumero('');
            setComplemento('');
            setBairro('');
            setCidade('');
            setUF('');

            setTimeout(function () { setSuccessMsg(false); }, 3000);

        } catch (error) {
            alert(error.response.data.message);
            //alert('Não foi possível realizar o seu cadastro no momento, tente novamente mais tarde.');
            //console.log(error);
        }
    }


    useEffect(() => {
        const suggestions = [];
        props.empresas.forEach(function (empresa) {
            suggestions.push(empresa.name);
        });


        const filteredEmpresas = [...new Set(suggestions)]

        setOptions(filteredEmpresas);
    }, [props.empresas]);


    props.empresas.sort((a, b) => {
        return a.name.localeCompare(b.name);
    });

    const resultSearch = item => {
        setEmpresa(item);
        setDisplay(false);
    }

    const filtered = options.filter((empresa, index) => options.indexOf(empresa) === index);

    return (
        <>
            <div className={props.open ? `${styles.modalBG} ${styles.showModal} ` : `${styles.modalBG}`}>
                <div className={styles.modalContato}>
                    <GrClose size={30} color="#ffffff" className={`${styles.modalIcon} ${styles.close}`} onClick={props.close} />
                    <div className={styles.identificacao}>
                        <h2>Cadastro Pessoa Física</h2>
                    </div>
                    <div className={styles.headerForm}>
                        <h2>Informações pessoais</h2>
                        <span><i>*preenchimento obrigatórios</i></span>
                    </div>
                    <div className={styles.modalContentContato}>
                        <form>

                            <div className={displayError ? `${styles.mbInput} errorCpf` : `${styles.mbInput}`}>
                                <InputMask mask="999.999.999-99" type="tel" name="cpf" placeholder="*CPF"
                                    onChange={(e) => setCpf(e.target.value)} value={cpf}
                                    onBlur={SearchClientData} />
                                <label><IoFingerPrintSharp color="#dbdbdb" size={18} /></label>
                                {displayError && <div className="errorCpf"><i>*CPF já cadastrado</i></div>}
                            </div>

                            <div className={styles.mbInput}>
                                <input type="text" name="nome" placeholder="*Nome" onChange={(e) => setNome(e.target.value)} value={nome} />
                                <label><FaUser color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <label style={{ display: 'flex', top: '10px' }}><FaBuilding color="#dbdbdb" size={18} /></label>
                                <input type="text" name="empresa" placeholder="*Empresa, Condomínio Residencial ou Casa" onChange={(e) => setEmpresa(e.target.value)} value={empresa} onKeyUp={() => setDisplay(!display)} />
                                {display && (
                                    <div className={display ? `${styles.empresas}` : "autocomplete"}>
                                        {filtered.filter(name => name.toLowerCase().indexOf(empresa.toLowerCase()) > -1).map((value, index) => {
                                            return (
                                                <div key={index} onClick={() => resultSearch(value)}>
                                                    {value}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )
                                }
                            </div>
                            <div className={styles.mbInput}>
                                <InputMask mask="(99) 99999-9999" type="tel" name="telefone" placeholder="*Telefone Celular" onChange={(e) => setTelefone(e.target.value)} value={telefone} />
                                <label><MdSmartphone color="#dbdbdb" size={18} /></label>
                                <div className={styles.infosInput}><p>(DDD + número)</p></div>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="email" name="email" placeholder="*e-mail" onChange={(e) => setEmail(e.target.value)} value={email} />
                                <label><GrMail color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <InputMask mask="99/99/9999" type="tel" name="data_nasc" placeholder="*data de nascimento" onChange={(e) => setDataNasc(e.target.value)} value={dataNasc} />
                                <label><AiFillCalendar color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={`${styles.mbInput} ${styles.inputRadios}`}>
                                <div><h3>Gênero</h3></div><br />
                                <div><input type="radio" name="genero" id="feminino" defaultChecked={genero === 'F'} value="F" onClick={(e) => setGenero('F')} /> Feminino</div>
                                <div><input type="radio" name="genero" id="masculino" defaultChecked={genero === 'M'} value="M" onClick={(e) => setGenero('M')} /> Masculino</div>
                                <div><input type="radio" name="genero" id="nao_informar" defaultChecked={genero === 'O'} value="O" onClick={(e) => setGenero('O')} /> Prefiro não informar</div>
                            </div>
                            <div className={styles.mbInput}>
                                <InputMask mask="99999-999" type="tel" name="cep" placeholder="*CEP" onChange={(e) => setCEP(e.target.value)} onBlur={pesquisaCEP} value={CEP} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="endereco" placeholder="*Endereço" onChange={(e) => setEndereco(e.target.value)} value={endereco} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="tel" name="numero" placeholder="*Número" onChange={(e) => setNumero(e.target.value)} value={numero} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="complemento" placeholder="Complemento" onChange={(e) => setComplemento(e.target.value)} value={complemento} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="bairro" placeholder="*Bairro" onChange={(e) => setBairro(e.target.value)} value={bairro} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="cidade" placeholder="*Cidade" onChange={(e) => setCidade(e.target.value)} value={cidade} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={styles.mbInput}>
                                <input type="text" name="uf" placeholder="*UF" onChange={(e) => setUF(e.target.value)} value={uf} />
                                <label><MdLocationOn color="#dbdbdb" size={18} /></label>
                            </div>
                            <div className={`${styles.mbInput} ${styles.inputCheckbox}`}>
                                <div><input type="checkbox" name="info_mail" value={news} onChange={e => setNews(e.target.checked)} checked={news === true} /> <p>Deseja receber novidades, ofertas e promoções por e-mail?</p></div>
                            </div>
                        </form>
                        {!displayError && <button className={styles.formButtonIdentifica} type="button" onClick={SendFormCadPF}>Cadastrar</button>}
                        {successMsg && <div className="sucesso"><FaCheck color="#63a36a" size={25} />&nbsp; Cadastro realizado com sucesso!</div>}
                    </div>
                </div>
            </div>
        </>
    );
}
